import { ActivatedRoute, Router } from '@angular/router';
import { Entities } from '@core/components/entities.enum';
import { FilterMatchMode, TableAction } from '@capturum/ui/api';
import { CapturumInfoTableComponent, InfoTableColumn } from '@capturum/ui/info-table';
import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { BaseListComponent } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { FilterConfig } from '@core/models/filter-config.model';
import { LayoutService } from '@shared/services/layout.service';
import { PageConfig, PageConfigAction } from '@core/models/page-config.model';
import { ConfirmationService, FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { NgxPermissionsService } from 'ngx-permissions';
import { ModuleService } from '@features/module/services/module.service';
import { first, map, takeUntil } from 'rxjs/operators';
import { StyleClass } from '@core/enums/style-class.enum';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { ApiIndexResult } from '@capturum/api';
import { Observable, Subject } from 'rxjs';

@Component({ template: '' })
export class IntBaseListComponent<T> extends BaseListComponent<T> implements OnInit {
  @ViewChild(CapturumInfoTableComponent, { static: false }) set tableRef(ref: CapturumInfoTableComponent) {
    if (ref) {
      this.infoTable = ref;
      this.infoTable.perPageOptions = [
        { label: '10', value: this.hasTotalRow ? 11 : 10 },
        { label: '20', value: this.hasTotalRow ? 21 : 20 },
        { label: '50', value: this.hasTotalRow ? 51 : 50 },
        { label: '100', value: this.hasTotalRow ? 101 : 100 },
      ];
      this.cdr.detectChanges();
    }
  }

  @Output()
  public getOptions = new EventEmitter<void>();

  public infoTable: CapturumInfoTableComponent;

  public tableColumns: InfoTableColumn[];
  public filterConfig: FilterConfig[];
  public dataLoaded = false;
  public hasTotalRow = false;
  public removeDataWrappers = false;
  public ngUnsubscribe: Subject<any> = new Subject();
  protected entity: Entities;
  protected router: Router;
  protected route: ActivatedRoute;
  protected layoutService: LayoutService;
  protected confirmService: ConfirmationService;
  protected moduleService: ModuleService;
  protected ngxPermissionService: NgxPermissionsService;

  constructor(public injector: Injector) {
    super(injector, injector.get(TranslateService));

    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.layoutService = injector.get(LayoutService);
    this.confirmService = injector.get(ConfirmationService);
    this.ngxPermissionService = injector.get(NgxPermissionsService);
    this.moduleService = injector.get(ModuleService);
  }

  public ngOnInit(): void {
    this.moduleService.currentModule$.pipe(
      first(),
    ).subscribe((module) => {
      this.layoutService.setPageConfig(this.getDefaultConfig());
    });
  }

    /**
     * Get the items
     *
     * @param event
     * @param items Optional object of item names
     *
     * @return Observable<any>
     */
    public readItems(event: LazyLoadEvent, items: any = this.itemNames): Observable<any> {
        return new Observable((observer) => {
            this.loading = true;

            const options = this.getListOptions();
            const indexMethod = this.indexMethodArguments ? this.apiService[this.indexMethod](options, ...this.indexMethodArguments) : this.apiService[this.indexMethod](options)

            this.requestOptions = options;
            this.ngUnsubscribe.next(false);
            indexMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: ApiIndexResult<any>) => {
                if (response?.meta?.pagination) {
                    this.paginator = { ...response.meta.pagination, rows: response.meta.pagination.per_page };

                    if (Number.isInteger(event?.first)) {
                        this.paginator.first = event.first;
                    }
                }

                this.loading = false;

                observer.next(response?.data);
            }, errorResponse => {
                this.loading = false;
                observer.error(errorResponse);
            });
        });
    }

  public add(): void {
    this.router.navigate([`../add`], { relativeTo: this.route });
  }

  public edit(id: string): void {
    this.router.navigate([`../${id}`], { relativeTo: this.route });
  }

  public filterGlobal(value: string): void {
    this.ngUnsubscribe.next(true);
    this.infoTable.primeNGTable.filterDelay = 1000;
    this.infoTable.primeNGTable.filterGlobal(value, 'contains');
  }

  public filter(event: { value: any, field: string, matchMode: string }): void {
    this.infoTable.primeNGTable.filterDelay = 300;
    this.infoTable.filterTable(event.value, event.field, event.matchMode as FilterMatchMode);
  }

  public reset(): void {
    this.sort = null;
    localStorage.removeItem(this.infoTable.stateKey);
    this.infoTable.primeNGTable.filters = Object.assign({});
    this.filters = null;
    this.infoTable.resetFilters();
    this.cdr.detectChanges();
  }

  public getViewEntityTableRowAction(icon: string): (TableAction&{ callback: (item: any) => void }) {
    return {
      label: this.translateService.instant('intergrip.entity.view', { entity: this.getEntityTranslation }),
      value: '',
      icon,
      callback: (item) => {
        this.edit(item.id);
      },
    };
  }

  public getShowHistoryTableRowAction(callback: (id: string) => void): TableAction {
    return {
      label: this.translateService.instant('intergrip.button.show-history'),
      value: '',
      icon: FontAwesomeIcon.History,
      callback: (item) => {
        callback(item.id);
      },
    };
  }

  public getDeleteEntityTableRowAction(): TableAction {
    return {
      label: this.translateService.instant('intergrip.entity.delete', {
        entity: this.getEntityTranslation,
      }),
      key: 'delete',
      hidden: this.moduleService.getCurrentModule().pipe(map((module) => {
        return !this.ngxPermissionService.getPermission(`${module}.${this.entity}.manage`);
      })),
      icon: FontAwesomeIcon.TrashAlt,
      callback: (item) => {
        this.deleteItemWithConfirm(item.id);
      },
    };
  }

  public getEditEntityTableRowAction(): TableAction {
    return {
      label: this.translateService.instant(`intergrip.entity.edit`, {
        entity: this.getEntityTranslation,
      }),
      hidden: !this.ngxPermissionService.getPermission(`intergrip.${this.entity}.manage`),
      icon: FontAwesomeIcon.Pencil,
      callback: (item) => {
        this.edit(item.id);
      },
    };
  }

  public get getEntityTranslation(): string {
    return this.translateService.instant(`${this.moduleService.currentModule}.${this.entity}.entity_name`);
  }

  public getDefaultConfig(module: string = this.moduleService.currentModule): PageConfig {
    return {
      title: this.translateService.instant(`${module}.${this.entity}.overview.title`),
      buttons: [
        this.defaultAddButton,
      ],
      selectOptions: [],
    };
  }

  public get defaultAddButton(): PageConfigAction {
    return {
      label: 'intergrip.entity.add',
      icon: FontAwesomeIcon.Plus,
      styleClass: StyleClass.Primary,
      translateParams: {
        entity: this.entity,
      },
      permissions: [`${this.moduleService.currentModule}.${this.entity}.manage`],
      callback: () => {
        this.router.navigate(['../add'], { relativeTo: this.route });
      },
    };
  }

  public deleteItemWithConfirm(id: number|string, item?: string, message?: string): void {
    const none = 'none';
    this.confirmationService.confirm({
      message: message ? message : this.translateService.instant('intergrip.mbo-locations.delete.title'),
      acceptLabel: this.translateService.instant('intergrip.entity.delete-item'),
      rejectLabel: this.translateService.instant('intergrip.entity.cancel'),
      acceptIcon: none,
      rejectIcon: none,
      acceptButtonStyleClass: 'border-0 bg-danger',
      accept: () => {
        this.deleteItem(id, item);
      },
      reject: () => {
        this.loading = false;
      },
    });
  }

  public deleteItem(id: number|string, item: string = this.itemNames.singular): void {
    if (this.apiService === null) {
      return;
    }

    this.loading = true;

    this.apiService.delete(id).subscribe(() => {
      this.tableVisible = false;


      this.loadTableDataFromCurrentLazyLoadEvent();

      this.toastService.success(this.translateService.instant('toast.success.title'), this.translateService.instant('list.item_deleted'));
    }, error => {
      this.handleError(error);

      this.loading = false;
    });
  }

  public getManageModulesTableActionRow(): TableAction {
    return {
      label: this.translateService.instant('intergrip.module.manage-modules'),
      permissions: ['intergrip.module.manage'],
      icon: FontAwesomeIcon.Cogs,
      callback: (item) => {
        const tenantId = item.tenant_id ? item.tenant_id : item?.tenant?.id;

        if (tenantId) {
          this.router.navigate([`/manage/${Entities.Module}/${this.entity}/${tenantId}/module`]);
        }
      },
    };
  }

  public get activeFilters(): { [key: string]: FilterMetadata|FilterMetadata[] } {
    if (this.infoTable && this.infoTable.activeFilters) {
      const activeFilters = this.infoTable.activeFilters;

      for (const key in activeFilters) {
        if (activeFilters.hasOwnProperty(key)) {
          const value = (activeFilters[key] as FilterMetadata).value;

          if (Date.parse(value) && !(value instanceof Date)) {
            activeFilters[key] = { ...activeFilters[key], value: new Date(value) };
          }
        }
      }

      return activeFilters;
    }

    return {};
  }

  public initFilters(): void {
    if (!this.dataLoaded) {
      this.dataLoaded = true;
      this.getOptions.emit();
    }
  }

  public updateFilterConfig(data: any): void {
    this.filterConfig = data;
  }
}
