import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TwoFactorAuthService } from '@features/auth/services/two-factor-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InputCodeComponent } from '@shared/components/input-code/input-code.component';
import { SetUser } from '@core/state/user/user.actions';
import { Store } from '@ngxs/store';
import { AuthService } from '../services/auth.service';
import { LocalParamsService } from '@core/services/local-params.service';

@Component({
  selector: 'app-two-factor-login',
  templateUrl: './two-factor-login.component.html',
  styleUrls: ['./two-factor-login.component.scss'],
})
export class TwoFactorLoginComponent implements OnInit, AfterViewInit {
  @ViewChild(InputCodeComponent)
  public inputCodeComponent: InputCodeComponent;

  public inputCodeControl: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(6)]);
  public method: string;

  constructor(
    private twoFactorAuthService: TwoFactorAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private authService: AuthService,
    private paramsService: LocalParamsService,
  ) {
  }

  public ngOnInit(): void {
    this.method = this.route.snapshot.queryParams.method;
  }

  public ngAfterViewInit(): void {
    this.inputCodeComponent.focus();
  }

  public onSubmit(): void {
    const params = this.route.snapshot.queryParams;

    this.twoFactorAuthService.login({
      user_id: params.userId,
      method: params.method,
      key: this.inputCodeControl.value,
    }).subscribe((response) => {
      localStorage.setItem('token', response.token);
      localStorage.setItem('user', JSON.stringify(response.user));
      const redirect = JSON.parse(localStorage.getItem('redirectAfterLogin'));
      if (redirect) {
        this.paramsService.roleKeyUpdated.next({
          roleKey: redirect.roleKey,
          route: redirect.route,
        });
        localStorage.removeItem('redirectAfterLogin');
      }

      this.store.dispatch(new SetUser(this.authService.getUser()));
      this.router.navigate(['/']);
    });
  }
}
